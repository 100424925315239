import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowDown: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '12', x2: '12', y1: '5', y2: '19' }],
    ['polyline', { points: '19 12 12 19 5 12' }],
  ],
];

export default ArrowDown;
