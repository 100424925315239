import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowLeftToLine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '7', x2: '21', y1: '12', y2: '12' }],
    ['polyline', { points: '13 6 7 12 13 18' }],
    ['path', { d: 'M3 19V5' }],
  ],
];

export default ArrowLeftToLine;
