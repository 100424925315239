import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowUpToLine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '12', x2: '12', y1: '7', y2: '21' }],
    ['polyline', { points: '18 13 12 7 6 13' }],
    ['path', { d: 'M5 3h14' }],
  ],
];

export default ArrowUpToLine;
