import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowUp: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '12', x2: '12', y1: '19', y2: '5' }],
    ['polyline', { points: '5 12 12 5 19 12' }],
  ],
];

export default ArrowUp;
