import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const LayoutList: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '7', height: '7', x: '3', y: '14', rx: '1' }],
    ['rect', { width: '7', height: '7', x: '3', y: '3', rx: '1' }],
    ['line', { x1: '14', x2: '21', y1: '4', y2: '4' }],
    ['line', { x1: '14', x2: '21', y1: '9', y2: '9' }],
    ['line', { x1: '14', x2: '21', y1: '15', y2: '15' }],
    ['line', { x1: '14', x2: '21', y1: '20', y2: '20' }],
  ],
];

export default LayoutList;
