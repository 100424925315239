import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowDownRight: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '7', x2: '17', y1: '7', y2: '17' }],
    ['polyline', { points: '17 7 17 17 7 17' }],
  ],
];

export default ArrowDownRight;
