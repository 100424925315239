import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowDownLeftFromCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '8,22 2,22 2,16 ' }],
    ['line', { x1: '2', y1: '22', x2: '12', y2: '12' }],
    ['path', { d: 'M2,12C2,6.5,6.5,2,12,2s10,4.5,10,10s-4.5,10-10,10' }],
  ],
];

export default ArrowDownLeftFromCircle;
