import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowDownRightFromCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '22,16 22,22 16,22 ' }],
    ['line', { x1: '22', y1: '22', x2: '12', y2: '12' }],
    ['path', { d: 'M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10' }],
  ],
];

export default ArrowDownRightFromCircle;
