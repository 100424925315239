import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowUpFromLine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '12', x2: '12', y1: '3', y2: '17' }],
    ['polyline', { points: '18 9 12 3 6 9' }],
    ['path', { d: 'M5 21h14' }],
  ],
];

export default ArrowUpFromLine;
