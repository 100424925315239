import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowDownLeft: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '17', x2: '7', y1: '7', y2: '17' }],
    ['polyline', { points: '17 17 7 17 7 7' }],
  ],
];

export default ArrowDownLeft;
