import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowRight: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '5', x2: '19', y1: '12', y2: '12' }],
    ['polyline', { points: '12 5 19 12 12 19' }],
  ],
];

export default ArrowRight;
