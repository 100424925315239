import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowRightToLine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '17', x2: '3', y1: '12', y2: '12' }],
    ['polyline', { points: '11 18 17 12 11 6' }],
    ['path', { d: 'M21 5v14' }],
  ],
];

export default ArrowRightToLine;
