import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowUpRightFromCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '16,2 22,2 22,8 ' }],
    ['line', { x1: '22', y1: '2', x2: '12', y2: '12' }],
    ['path', { d: 'M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2' }],
  ],
];

export default ArrowUpRightFromCircle;
