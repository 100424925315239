import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowRightFromLine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '21', x2: '7', y1: '12', y2: '12' }],
    ['polyline', { points: '15 18 21 12 15 6' }],
    ['path', { d: 'M3 5v14' }],
  ],
];

export default ArrowRightFromLine;
