import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowLeftCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '10' }],
    ['polyline', { points: '12 8 8 12 12 16' }],
    ['line', { x1: '16', x2: '8', y1: '12', y2: '12' }],
  ],
];

export default ArrowLeftCircle;
