import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronDownSquare: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '18', height: '18', x: '3', y: '3', rx: '2', ry: '2' }],
    ['polyline', { points: '16,10 12,14 8,10' }],
  ],
];

export default ChevronDownSquare;
