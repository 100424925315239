import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowUpLeftFromCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12' }],
    ['polyline', { points: '2,8 2,2 8,2 ' }],
    ['line', { x1: '2', y1: '2', x2: '12', y2: '12' }],
  ],
];

export default ArrowUpLeftFromCircle;
