import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ArrowUpFromDot: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '12', x2: '12', y1: '16', y2: '2' }],
    ['polyline', { points: '5,9 12,2 19,9' }],
    ['circle', { cx: '12', cy: '21', r: '1' }],
  ],
];

export default ArrowUpFromDot;
